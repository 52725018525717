import _get from 'lodash/get';
import Link from 'next/link';
import React from 'react';

import { Container, Paper, Box, Typography } from '@material-ui/core';

import { useAuth } from '../hooks/useAuth';

function ErrorPage() {
  const auth = useAuth();

  return (
    <Container maxWidth="md">
      <Box marginTop={5}>
        <Paper>
          <Box padding={(3, 5)} textAlign="center">
            <Typography>An unexpected error has occurred.</Typography>
            {auth.user && (
              <Typography>
                <Link href="/forms">
                  <a>Return to dashboard</a>
                </Link>
              </Typography>
            )}
          </Box>
        </Paper>
      </Box>
    </Container>
  );
}

export default ErrorPage;
